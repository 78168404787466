<template>
  <b-overlay
    :show="formShow"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
    no-fade
  >
    <div id="plannerCover">
      <div id="buttonsCard">
        <b-row class="align-items-center bg-white">
          <!-- filter trade date -->
          <b-col cols="6" md="6" xl="2" lg="2" sm="6">
            <div class="d-flex align-items-center">
              <label class="align-items-center justify-content-center mr-1"
                >Starting Date:</label
              >

              <flat-pickr
                size="sm"
                v-model="dateDefault"
                :config="{ dateFormat: 'd/m/Y', locale: { firstDayOfWeek: 1 } }"
                placeholder="DD/MM/YYYY"
                class="form-control"
              />
            </div>
          </b-col>

          <b-col cols="6" md="6" xl="2" lg="2" sm="6">
            <div class="d-flex align-items-center">
              <label class="align-items-center justify-content-center mr-1"
                >Sort By</label
              >

              <v-select
                v-model="referenceSorting"
                :options="['Group Reference', 'Foreman']"
                :clearable="false"
                style="width:180px;"
              >
              </v-select>
            </div>
          </b-col>

          <!-- list page buttons -->
          <b-col
            cols="12"
            md="12"
            xl="8"
            lg="8"
            sm="12"
            class="d-flex justify-content-end align-items-center list-pages-buttons"
          >
            <!-- search for trades -->
            <b-button
              v-show="$Can('event_delete_btn') && !deleteSelect"
              class="mr-25 mobilHidden btn-sm"
              :variant="editSelect ? 'info' : 'outline-info'"
              @click="editStart()"
            >
              Edit Mode
            </b-button>

            <b-button
              v-show="$Can('event_delete_btn') && !editSelect"
              class="mr-25 mobilHidden btn-sm"
              :variant="deleteSelect ? 'danger' : 'outline-danger'"
              @click="deleteStart()"
            >
              Delete Mode
            </b-button>

            <b-button
              v-show="$Can('event_send_btn')"
              :id="'popover-send-email'"
              class="ml-25 btn-sm"
              variant="warning"
              @contextmenu.prevent="handleClickEmail()"
            >
              <span><feather-icon icon="SendIcon" />Send E-mail</span>
            </b-button>

            <b-popover
              :id="'popover-send-email'"
              :target="'popover-send-email'"
              ref="popover-mail"
              triggers="click"
              :show.sync="mailShow"
              placement="auto"
              container="my-mail-container"
              @show="onMailShow()"
            >
              <template v-slot:title>
                <div class="d-flex justify-content-between align-items-center">
                  <span>Mailing box</span>
                  <b-button
                    class="close"
                    variant="transparent"
                    aria-label="Close"
                    @click="onMailClose()"
                  >
                    <span class="d-inline-block text-white" aria-hidden="true"
                      >&times;</span
                    >
                  </b-button>
                </div>
              </template>

              <div>
                <b-form-group label="E-mail List:" label-cols="6" class="mb-1">
                  <v-select
                    input-id="staffInput"
                    :reduce="(val) => val.email"
                    label="full_name"
                    v-model="selectMail"
                    :options="activeMailList"
                    @input="mailSelect()"
                    :clearable="false"
                    class="select-size-sm"
                  >
                  </v-select>
                </b-form-group>

                <b-form-group
                  label="E-mail address:"
                  label-for="mail-address"
                  label-cols="6"
                  class="mb-1"
                >
                  <b-form-input
                    id="mail-address"
                    v-on:keydown.enter="mailTextSelect()"
                    v-model.lazy="selectTextMail"
                    size="sm"
                  />
                  <small>Press ENTER to save</small>
                </b-form-group>

                <div v-if="selectMailList.length > 0">
                  <hr />

                  <span>Recipient List: </span>
                  <b-alert show class="small text-center p-50">
                    <b-badge
                      variant="info"
                      class="m-25"
                      @click="removeMailList(index)"
                      style="cursor: pointer"
                      v-for="(x, index) in selectMailList"
                      :key="index"
                    >
                      {{ x }}
                    </b-badge>
                  </b-alert>
                </div>

                <div class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="danger"
                    class="mr-1"
                    @click="onMailClose()"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    @click="onMailSend()"
                  >
                    Send Mail
                  </b-button>
                </div>
              </div>
            </b-popover>

            <b-button
              class="ml-25 btn-sm"
              variant="info"
              @click="downloadPages()"
            >
              <span><feather-icon icon="PrinterIcon" />Print</span>
            </b-button>

            <b-button
              v-show="$Can('event_add_btn')"
              variant="primary"
              class="text-nowrap ml-25 btn-sm"
              router-link
              to="/event/event-add"
            >
              <span>Add Job</span>
            </b-button>
          </b-col>

          
          <b-col cols="12" class="d-flex align-items-center justify-content-center">
            <div show v-if="weekDataProcess == false">
              <strong>{{ perYear - 1 }} </strong>

              <span v-for="page in allWeeks" :key="page">
                <span v-if="page == currentPagePastYear && currentPage == 0 && currentPageNextYear == 0">
                  <button
                    class="btn btn-primary btn-sm"
                    style="margin-right:2px; margin-bottom:3px"
                  >
                    {{ page }}
                  </button>
                </span>
               

                <span v-else>
                  <button
                    @click="pageSelectPastYear(page)"
                    class="btn btn-sm weekNumbers"
                    :class="
                      showWeeks.findIndex((e) => e == page) != -1
                        ? saveDatas[showWeeks.findIndex((e) => e == page)]
                            .maxEvent > 0
                          ? 'btn-success'
                          : 'btn-outline-primary'
                        : 'btn-outline-primary'
                    "
                    pill
                  >
                    {{ page }}
                  </button>
                </span>
              </span>
            </div>
          </b-col>



          <b-col cols="12" class="d-flex align-items-center justify-content-center">
            <div show v-if="weekDataProcess == false">
             <strong>
              {{ perYear }}
             </strong> 
              <span v-for="page in allWeeks" :key="page">
                <span v-if="page == currentPage && currentPageNextYear == 0 && currentPagePastYear == 0">
                  <button
                    class="btn btn-primary btn-sm"
                    style="margin-right:2px; margin-bottom:3px"
                  >
                    {{ page }}
                  </button>
                </span>
                <span v-else-if="page == thisWeek">
                  <button
                    class="btn btn-outline-info btn-sm weekNumbers"
                    @click="pageSelect(page)"
                  >
                    {{ page }}
                  </button>
                </span>

                <span v-else>
                  <button
                    @click="pageSelect(page)"
                    class="btn btn-sm weekNumbers"
                    :class="
                      showWeeks.findIndex((e) => e == page) != -1
                        ? saveDatas[showWeeks.findIndex((e) => e == page)]
                            .maxEvent > 0
                          ? 'btn-success'
                          : 'btn-outline-primary'
                        : 'btn-outline-primary'
                    "
                    pill
                  >
                    {{ page }}
                  </button>
                </span>
              </span>
            </div>
          </b-col>
      

          <b-col cols="12" class="d-flex align-items-center justify-content-center">
            <div show v-if="weekDataProcess == false">
              <strong>{{ perYear + 1 }} </strong>

              <span v-for="page in allWeeks" :key="page">
                <span v-if="page == currentPageNextYear && currentPage == 0 && currentPagePastYear == 0">
                  <button
                    class="btn btn-primary btn-sm"
                    style="margin-right:2px; margin-bottom:3px"
                  >
                    {{ page }}
                  </button>
                </span>
               

                <span v-else>
                  <button
                    @click="pageSelectNextYear(page)"
                    class="btn btn-sm weekNumbers"
                    :class="
                      showWeeks.findIndex((e) => e == page) != -1
                        ? saveDatas[showWeeks.findIndex((e) => e == page)]
                            .maxEvent > 0
                          ? 'btn-success'
                          : 'btn-outline-primary'
                        : 'btn-outline-primary'
                    "
                    pill
                  >
                    {{ page }}
                  </button>
                </span>
              </span>
            </div>
          </b-col>


        </b-row>
      </div>

      <b-row
        style="background-color:white;"
        :style="'height:' + (windowHeight - 275) + 'px'"
        id="weeksTwentyOne"
      >
        <table v-if="saveDatas.length > 0">
          <thead>
            <tr>
              <th></th>
              <td
                :style="{
                  'background-color':
                    weekNumber % 2 != 1
                      ? 'rgba(138, 190, 38)'
                      : 'rgba(138, 190, 38,0.85)',
                }"
                v-for="(count, weekNumber) in saveDataWeeks"
                :colspan="count"
              >
                WEEK {{ weekNumber }}
              </td>
            </tr>

            <tr>
              <th>Invoice Status</th>
              <td
                v-for="(status, index) in invoiceStatuses"
                class="plannerTd"
                :style="{
                  'background-color':
                    status === 'Invoiced' ? '#29A632' : '#BF0B3B',
                }"
              >
                {{ status }}
              </td>
            </tr>
            <tr>
              <th>Date</th>
              <td
                ref="dateTd"
                class="plannerTd"
                v-for="data in saveDatas"
                style="color:black"
                :style="{
                  'background-color':
                    data.dayName == 'Saturday' || data.dayName == 'Sunday'
                      ? 'rgba(215, 215, 215,0.5)'
                      : 'rgba(255, 255, 255,0.5)',
                }"
              >
                {{ data.date }}
              </td>
            </tr>

            <tr>
              <th>Day</th>
              <td
                class="plannerTd"
                style="color:black"
                :style="
                  'background-color:' +
                    data.dayColour +
                    '; color:' +
                    data.dayTextColour
                "
                v-for="data in saveDatas"
              >
                {{ data.dayName }}
              </td>
            </tr>

            <tr class="font">
              <th>Total Contract</th>
              <td
                class="plannerTd"
                v-for="data in saveDatas"
                style="color:black"
                :style="{
                  'background-color':
                    data.dayName == 'Saturday' || data.dayName == 'Sunday'
                      ? 'rgba(215, 215, 215,0.5)'
                      : 'rgba(255, 255, 255,0.5)',
                }"
              >
                {{ data.totalContracts }}
              </td>
            </tr>

            <tr class="font">
              <th>Staff Deployed</th>
              <td
                class="plannerTd"
                v-for="data in saveDatas"
                style="color:black"
                :style="{
                  'background-color':
                    data.dayName == 'Saturday' || data.dayName == 'Sunday'
                      ? 'rgba(215, 215, 215,0.5)'
                      : 'rgba(255, 255, 255,0.5)',
                }"
              >
                {{ data.employeesDeployed }}
              </td>
            </tr>
          </thead>

          <tbody
            :style="xIndex % 2 ? 'background-color:#F0F8F080' : ''"
            v-for="(x, xIndex) in saveDatas[0].jobs.length"
          >
            <tr>
              <td style="background-color: white;">&nbsp;</td>
            </tr>

            <tr v-if="deleteSelect == true">
              <th
                class="text-white leftTitle"
                style="background-color:rgb(191, 11, 59); border-right: none;"
              >
                Delete
              </th>
              <td
                class="text-center"
                style="background-color:rgb(191, 11, 59);  cursor:pointer"
                v-for="(y, yIndex) in saveDatas.length"
              >
                <b-form-checkbox
                  @change="
                    deleteCheckboxAction(
                      saveDatas[yIndex].jobs[xIndex].id,
                      '<strong>' +
                        dateFormat(saveDatas[yIndex].jobs[xIndex].date) +
                        '</strong> ' +
                        saveDatas[yIndex].jobs[xIndex].location,
                      $event
                    )
                  "
                  plain
                  v-if="saveDatas[yIndex].jobs[xIndex].id != null"
                >
                </b-form-checkbox>
              </td>
            </tr>
            <tr v-else-if="editSelect == true">
              <th
                class="text-white leftTitle"
                style="background-color:rgb(11, 14, 191); border-right: none;"
              >
                Edit
              </th>
              <td
                class="text-center"
                style="background-color:rgb(11, 14, 191);  cursor:pointer"
                v-for="(y, yIndex) in saveDatas.length"
              >
                <b-form-checkbox
                  @change="
                    editCheckboxAction(
                      saveDatas[yIndex].jobs[xIndex].id,
                      '<strong>' +
                        dateFormat(saveDatas[yIndex].jobs[xIndex].date) +
                        '</strong> ' +
                        saveDatas[yIndex].jobs[xIndex].location,
                      saveDatas[yIndex].jobs[xIndex].groupReference,
                      $event
                    )
                  "
                  plain
                  v-if="
                    saveDatas[yIndex].jobs[xIndex].id != null &&
                      (editReference
                        ? saveDatas[yIndex].jobs[xIndex].groupReference ==
                          editReference
                        : true)
                  "
                >
                </b-form-checkbox>
              </td>
            </tr>

            <tr>
              <th class="leftTitle">Status</th>
              <td
                class="plannerTd"
                :style="{
                  'background-color':
                    saveDatas[yIndex].jobs[xIndex].id == null &&
                    saveDatas[yIndex].jobs[xIndex].status == null
                      ? indexColors[yIndex]
                      : '',
                }"
                :class="{
                  wsuccess:
                    saveDatas[yIndex].jobs[xIndex].id != null &&
                    saveDatas[yIndex].jobs[xIndex].status ==
                      'Assignment Completed',
                  wwarning:
                    saveDatas[yIndex].jobs[xIndex].id != null &&
                    saveDatas[yIndex].jobs[xIndex].status ==
                      'Awaiting Assignment',
                  wwsecondary:
                    saveDatas[yIndex].jobs[xIndex].id != null &&
                    saveDatas[yIndex].jobs[xIndex].status == 'Draft',
                }"
                @contextmenu.prevent="
                  handleClickEvent($event, saveDatas[yIndex].jobs[xIndex], x, y)
                "
                v-for="(y, yIndex) in saveDatas.length"
              >
                <span
                  v-if="
                    saveDatas[yIndex].jobs[xIndex].id != null &&
                      saveDatas[yIndex].jobs[xIndex].status ==
                        'Assignment Completed'
                  "
                >
                  <span class="">Assignment Completed</span>
                </span>

                <span
                  v-if="
                    saveDatas[yIndex].jobs[xIndex].id != null &&
                      saveDatas[yIndex].jobs[xIndex].status ==
                        'Awaiting Assignment'
                  "
                >
                  <span class="">Awaiting Assignment</span>
                </span>

                <span
                  v-if="
                    saveDatas[yIndex].jobs[xIndex].id != null &&
                      saveDatas[yIndex].jobs[xIndex].status == 'Draft'
                  "
                >
                  <span class="">Draft</span>
                </span>
              </td>
            </tr>

            <tr>
              <th class="leftTitle">PO Number</th>
              <td
                :style="{ 'background-color': indexColors[yIndex] }"
                class="plannerTd"
                @contextmenu.prevent="
                  handleClickEvent($event, saveDatas[yIndex].jobs[xIndex], x, y)
                "
                v-for="(y, yIndex) in saveDatas.length"
              >
                {{ saveDatas[yIndex].jobs[xIndex].po_number }}
              </td>
            </tr>

            <tr>
              <th class="leftTitle">Group Reference</th>
              <td
                class="plannerTd"
                :style="{ 'background-color': indexColors[yIndex] }"
                style="font-size: 0.825em;"
                @contextmenu.prevent="
                  handleClickEvent($event, saveDatas[yIndex].jobs[xIndex], x, y)
                "
                v-for="(y, yIndex) in saveDatas.length"
              >
                {{ saveDatas[yIndex].jobs[xIndex].groupReference }}
              </td>
            </tr>

            <tr>
              <th class="leftTitle">Invoice No</th>
              <td
                class="plannerTd"
                :style="{
                  'background-color':
                    saveDatas[yIndex].jobs[xIndex].invoice_number != null
                      ? '#ecff17'
                      : indexColors[yIndex],
                }"
                @contextmenu.prevent="
                  handleClickEvent($event, saveDatas[yIndex].jobs[xIndex], x, y)
                "
                v-for="(y, yIndex) in saveDatas.length"
              >
                {{ saveDatas[yIndex].jobs[xIndex].invoice_number }}
              </td>
            </tr>

            <tr>
              <th class="leftTitle">Client</th>
              <td
                class="plannerTd"
                @contextmenu.prevent="
                  handleClickEvent($event, saveDatas[yIndex].jobs[xIndex], x, y)
                "
                v-for="(y, yIndex) in saveDatas.length"
                :style="
                  'background-color: ' +
                    (saveDatas[yIndex].jobs[xIndex].client
                      ? saveDatas[yIndex].jobs[xIndex].client.colour
                      : indexColors[yIndex]) +
                    '; color: ' +
                    (saveDatas[yIndex].jobs[xIndex].client
                      ? saveDatas[yIndex].jobs[xIndex].client.textColour
                      : indexColors[yIndex])
                "
              >
                {{
                  saveDatas[yIndex].jobs[xIndex].client
                    ? saveDatas[yIndex].jobs[xIndex].client.name
                    : ""
                }}
              </td>
            </tr>

            <tr>
              <th class="leftTitle">Location</th>
              <td
                class="plannerTd"
                :style="{ 'background-color': indexColors[yIndex] }"
                @contextmenu.prevent="
                  handleClickEvent($event, saveDatas[yIndex].jobs[xIndex], x, y)
                "
                v-for="(y, yIndex) in saveDatas.length"
              >
                {{ saveDatas[yIndex].jobs[xIndex].location }}
              </td>
            </tr>

            <tr>
              <th class="leftTitle">Congestion Charge</th>
              <td
                class="plannerTd"
                :style="{ 'background-color': indexColors[yIndex] }"
                @contextmenu.prevent="
                  handleClickEvent($event, saveDatas[yIndex].jobs[xIndex], x, y)
                "
                v-for="(y, yIndex) in saveDatas.length"
              >
                {{ saveDatas[yIndex].jobs[xIndex].congestion_charge }}
              </td>
            </tr>

            <tr>
              <th class="leftTitle">Employess Required</th>
              <td
                class="plannerTd"
                :style="{ 'background-color': indexColors[yIndex] }"
                @contextmenu.prevent="
                  handleClickEvent($event, saveDatas[yIndex].jobs[xIndex], x, y)
                "
                v-for="(y, yIndex) in saveDatas.length"
              >
                {{ saveDatas[yIndex].jobs[xIndex].employess_required }}
              </td>
            </tr>

            <tr>
              <th class="leftTitle">Actual</th>
              <td
                class="plannerTd"
                :style="{ 'background-color': indexColors[yIndex] }"
                @contextmenu.prevent="
                  handleClickEvent($event, saveDatas[yIndex].jobs[xIndex], x, y)
                "
                v-for="(y, yIndex) in saveDatas.length"
              >
                {{ saveDatas[yIndex].jobs[xIndex].actual }}
              </td>
            </tr>

            <staff-component
              :index-colors="indexColors"
              :staff-list="staffList"
              :edit-list="editList"
              ref="staffComponentRef"
              @get-select-week="getSelectWeek"
              :form-show.sync="formShow"
              :save-datas="saveDatas"
              :x-index="xIndex"
              :modal-id="'modal-staff-group-dates-' + xIndex"
              :modal-group-dates="'modal-staff-group-dates-' + xIndex"
            ></staff-component>

            <hotel-component
              :index-colors="indexColors"
              @get-select-week="getSelectWeek"
              :edit-list="editList"
              :new-hotel-y.sync="newHotelY"
              :new-hotel-x.sync="newHotelX"
              :new-hotel-job.sync="newHotelJob"
              :modal-id="'modal-hotel-' + xIndex"
              :modal-group-dates="'modal-group-dates-' + xIndex"
              :x="x"
              :form-show.sync="formShow"
              :save-datas="saveDatas"
              :x-index="xIndex"
            ></hotel-component>

            <note-component
              :index-colors="indexColors"
              @get-select-week="getSelectWeek"
              :x="x"
              :form-show.sync="formShow"
              :edit-list="editList"
              :save-datas="saveDatas"
              :x-index="xIndex"
              :note-editing.sync="noteEditing"
            ></note-component>
          </tbody>

          <div style="margin-bottom: 50px;"></div>
        </table>
      </b-row>

      <vue-context
        v-if="$Can('event_edit_btn') || $Can('event_information_tab')"
        ref="eventMenuData"
      >
        <li>
          <b-link
            v-for="data in eventMenuData"
            :key="data.text"
            class="d-flex align-items-center"
            @click="optionClickedEvent(data.text, data)"
          >
            <feather-icon :icon="data.icon" size="12" />
            <span class="ml-75">{{ data.text }}</span>
          </b-link>
        </li>
      </vue-context>

      <b-modal
        id="modal-duplicate"
        cancel-variant="outline-secondary"
        ok-title="Save"
        @ok="handleUpdateDuplicate"
        cancel-title="Close"
        :no-close-on-backdrop="true"
        centered
      >
        <template #modal-header="{ close }">
          <span>Duplicate Event</span>
        </template>
        <b-form ref="duplicateRules">
          <b-form-group
            :state="duplicateState"
            invalid-feedback="Date field is required"
            label="Date*"
            label-for="date"
          >
            <flat-pickr
              id="date"
              :state="duplicateState"
              input-id="date"
              v-model="duplicateData.date"
              class="form-control"
              :config="{
                mode: 'multiple',
                locale: { firstDayOfWeek: 1 },
                dateFormat: 'd/m/Y',
                disable: disabledDates,
              }"
            />
          </b-form-group>
        </b-form>
      </b-modal>

      <b-modal
        id="modal-group-dates"
        ref="modal-group-dates"
        cancel-variant="outline-secondary"
        ok-title="Save"
        @ok="handleUpdateGroupDates"
        cancel-title="Close"
        :no-close-on-backdrop="true"
        centered
        @hide="groupDatesHandleCancel"
      >
        <template #modal-header="{ close }">
          <span>Select Group Dates</span>
        </template>

        <b-form ref="groupDatesRules">
          <b-form-group
            :state="groupDatesState"
            invalid-feedback="Date field is required"
            label="Date*"
            label-for="date"
          >
            <flat-pickr
              id="date"
              :state="groupDatesState"
              input-id="date"
              v-model="groupDates"
              class="form-control"
              :config="configPickr"
            />
          </b-form-group>
        </b-form>
      </b-modal>
    </div>

    <div
      v-if="deleteList.length > 0"
      style="position: absolute; width: 100%;    z-index: 1059;    border-radius: 15px; box-shadow:0px 0px 15px 0px #133842"
    >
      <b-button
        variant="danger"
        style="position: relative; width: 100%;"
        @click="deleteEvents()"
      >
        <feather-icon icon="Trash2Icon" /> Delete Selected Events</b-button
      >
    </div>
  </b-overlay>
</template>

<script>
import {
  BPopover,
  BButton,
  BAlert,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BCard,
  VBModal,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BLink,
  VBToggle,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import moment from "moment";
import homeStoreModule from "./homeStoreModule";
import store from "@/store";
import { ref, onUnmounted, reactive } from "@vue/composition-api";
import router from "@/router";
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { BIcon } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Treeselect from "@riophae/vue-treeselect";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueContext from "vue-context";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css"; // Import the Flatpickr CSS

import axiosIns from "@/libs/axios";
import Cleave from "vue-cleave-component";
import { debounce } from "lodash";
import NoteComponent from "./NoteComponent.vue";
import HotelComponent from "./HotelComponent.vue";
import StaffComponent from "./StaffComponent.vue";

import _ from "lodash";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    BIcon,
    VBToggle,
    Treeselect,
    ToastificationContent,
    VueContext,
    flatPickr,
    BPopover,
    BButton,
    BAlert,
    BFormGroup,
    BFormSelect,
    BFormInput,
    Cleave,

    NoteComponent,
    HotelComponent,
    StaffComponent,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },

  setup() {
    const HOME_PLANNER_APP_STORE_MODULE_NAME = "home_planner";
    // Register module
    if (!store.hasModule(HOME_PLANNER_APP_STORE_MODULE_NAME))
      store.registerModule(HOME_PLANNER_APP_STORE_MODULE_NAME, homeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_PLANNER_APP_STORE_MODULE_NAME))
        store.unregisterModule(HOME_PLANNER_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {
    this.formShow = true;
    this.getSelectWeek();
    // window.addEventListener("resize", this.getWindowHeight);
    this.debouncedGetWindowHeight = debounce(this.getWindowHeight, 300);
    window.addEventListener("resize", this.debouncedGetWindowHeight);
  },

  computed: {
    configPickr() {
      return {
        mode: "multiple",
        locale: { firstDayOfWeek: 1 },
        dateFormat: "d/m/Y",
        enable: this.enabledGroupDates,
        allowInput: true,
        plugins: [
          ShortcutButtonsPlugin({
            button: [
              {
                label: "Select All",
              },
              {
                label: "Clear Selection",
              },
              {
                label: "Until Job",
              },
              {
                label: "After Job",
              },
              {
                label: "Until Today",
              },
              {
                label: "After Today",
              },
            ],
            label: "or",
            onClick: (index, fp) => {
              console.log(this.removeEventId.date);
              let targetDateParts = this.removeEventId.date.split("-"); // Split the date string
              let targetDate = new Date(
                targetDateParts[0], // Year
                targetDateParts[1] - 1, // Month (subtract 1 since months are 0-indexed)
                targetDateParts[2] // Day
              );
              let today = new Date();

              let dates = [];
              switch (index) {
                case 0:
                  dates = this.enabledGroupDates;
                  break;
                case 1:
                  dates = [];
                  break;

                case 2:
                  dates = this.enabledGroupDates.filter((dateString) => {
                    // Convert the string date to a JavaScript Date object

                    return new Date(dateString) < targetDate;
                  });

                  break;
                case 3:
                  dates = this.enabledGroupDates.filter((dateString) => {
                    // Compare the date to the target date

                    return new Date(dateString) > targetDate;
                  });

                  break;
                case 4:
                  dates = this.enabledGroupDates.filter((dateString) => {
                    // Compare the date to the target date

                    return new Date(dateString) < today;
                  });

                  break;
                case 5:
                  dates = this.enabledGroupDates.filter((dateString) => {
                    // Compare the date to the target date

                    return new Date(dateString) > today;
                  });

                  break;
              }
              if (dates.length > 0) {
                let newDates = [];
                dates.forEach((element) => {
                  let newDate = new Date(element);

                  const day = newDate.getUTCDate();
                  const month = newDate.getUTCMonth() + 1; // Months are 0-indexed, so add 1
                  const year = newDate.getUTCFullYear();
                  const dmYDate = `${day}/${month}/${year}`;
                  newDates.push(dmYDate);
                });

                fp.setDate(newDates);
              } else {
                fp.setDate([]);
              }
            },
          }),
        ],
      };
    },

    groupDatesState() {
      return this.groupDates != null && this.groupDates != "" ? true : false;
    },

    duplicateState() {
      return this.duplicateData.date != null && this.duplicateData.date != ""
        ? true
        : false;
    },

    invoiceStatuses() {
      return this.saveDatas.map((xDay) => {
        if (xDay.totalContracts === 0) {
          return "Not Invoiced";
        }

        const notInvoiced = xDay.jobs.some((job) => {
          if (job !== null && job.invoiceStatus !== undefined) {
            return !job.invoiceStatus;
          }
          return false;
        });
        return notInvoiced ? "Not Invoiced" : "Invoiced";
      });
    },
  },

  data() {
    const saveDatas = [];
    const dateNow = moment(new Date()).format("DD/MM/YYYY");
    let howManyWeek = moment(new Date()).format(
      moment(new Date()).format("YYYY-MM-DD")
    );
    let howManyWeekNumber = moment(howManyWeek).format("W");
    let thisWeek = parseInt(howManyWeekNumber);

    var duplicateData = {
      id: null,
      date: null,
    };

    return {
      staffList: [],
      newHotelJob: null,
      newHotelX: null,
      newHotelY: null,
      modalEventId: null,
      saveDatas,
      saveDataWeeks: [],
      saveDataWeeksNew: [],
      formShow: false,
      perYearOptions: [new Date().getFullYear(), new Date().getFullYear() + 1],
      perYear: new Date().getFullYear() + 1,
      perEVENTNumbers: [],
      selectPoNumber: null,
      deleteSelect: false,
      weekDataProcess: false,
      currentPagePastYear : 0,
      currentPage: 0,
      currentPageNextYear:0,
      allWeeks: 52,
      thisWeek,
      showWeeks: [],
      enabledGroupDates: [],
      windowHeight: window.innerHeight,
      isClick: true,
      selectedStaffId: null,
      groupDates: null,
      selectedEventId: null,

      dateDefault: dateNow,
      referenceSorting:
        localStorage.getItem("referenceSorting") ?? "Group Reference",
      noteEditing: null,
      deleteList: [],
      eventMenuData: [],

      dataJobDatesCount: 0,
      indexColors: [],

      editList: [],
      editSelect: false,
      editReference: null,

      removeEventId: null,
      duplicateData: duplicateData,
      disabledDates: [],

      modalSelectDate: null,

      eventEmployeeList: [],

      eventMenuData: [
        { icon: "PlusIcon", text: "New" },
        { icon: "FileIcon", text: "Open" },
        { icon: "SaveIcon", text: "Save" },
        { icon: "SaveIcon", text: "Save As" },
        { icon: "XIcon", text: "Close" },
      ],

      mailShow: false,
      activeMailList: [],
      selectMailList: [],
      selectMail: null,
      selectTextMail: null,
    };
  },

  watch: {
    dateDefault(val) {
      this.formShow = true;
      this.getSelectWeek();
    },

    referenceSorting(val) {
      this.formShow = true;
      this.getSelectWeek();
      localStorage.setItem("referenceSorting", val);
    },
  },

  destroyed() {
    // this.onMouseUp();
  },

  beforeDestroy() {
    // window.removeEventListener("resize", this.getWindowHeight);
    window.removeEventListener("resize", this.debouncedGetWindowHeight);
  },

  methods: {

    pageSelectPastYear(week) {
      this.formShow = true;
      this.currentPage = 0;
      this.currentPagePastYear = week;
      this.currentPageNextYear = 0;

      let year = new Date().getFullYear();
      // let weekStartDate = moment()
      //   .isoWeek(week)
      //   .startOf("isoWeek")
      //   .year(2024)
      //   .format("DD/MM/YYYY");
      // this.dateDefault = weekStartDate;
      let func = moment({year:year})



        func.startOf('week').add(week -1, 'week');



      // Return result using english locale
      let weekStartDate = func.locale('en').format('DD/MM/YYYY')
      this.dateDefault = weekStartDate;

      // this.getSelectWeek();
    },

    pageSelect(week) {
      this.formShow = true;
      this.currentPage = week;
      this.currentPageNextYear = 0;
      this.currentPagePastYear = 0;

      let year = new Date().getFullYear();
      let weekStartDate = moment()
        .year(year)
        .isoWeek(week)
        .startOf("isoWeek")
        .format("DD/MM/YYYY");
      this.dateDefault = weekStartDate;
      // this.getSelectWeek();
    },

    pageSelectNextYear(week) {
      this.formShow = true;
      this.currentPage = 0;
      this.currentPagePastYear = 0;

      this.currentPageNextYear = week;
      let year = new Date().getFullYear() + 1;
      let weekStartDate = moment()
        .year(year)
        .isoWeek(week)
        .startOf("isoWeek")
        .format("DD/MM/YYYY");
      this.dateDefault = weekStartDate;
      // this.getSelectWeek();
    },

    kFormatter,

    dateFormat(val) {
      moment.locale("en-US");
      return moment(val, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    dateFormatYmdToYmd(val) {
      moment.locale("en-US");
      return moment(val, "YYYY-MM-DD").format("YYYY-MM-DD");
    },

    dateFormatDMY(val) {
      moment.locale("en-US");
      return moment(val, "YYYY-MM-DD").format("DD-MM-YYYY");
    },

    dateFormatYMD(val) {
      moment.locale("en-US");
      return moment(val, "DD/MM/YYYY").format("YYYY-MM-DD");
    },

    getSelectWeek() {
      this.formShow = true;

      let selection = "groupReference";
      if (this.referenceSorting == "Group Reference") {
        selection = "groupReference";
      } else {
        selection = "foreman";
      }
      store
        .dispatch("home_planner/showAllEvents", {
          startDate: this.dateDefault,
          referenceSorting: selection,
        })
        .then((res) => {
          this.enabledGroupIds = [];

          this.enabledGroupDates = [];
          this.groupDates = [];

          this.allWeeks = res.data.howManyWeeks;
          this.saveDatas = res.data.data;
          this.saveDataWeeks = res.data.data.reduce((acc, event) => {
            if (!acc[event.weekNumber]) {
              acc[event.weekNumber] = 0;
            }
            acc[event.weekNumber]++;
            return acc;
          }, {});
          this.getUsers();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUsers() {
      store
        .dispatch("home_planner/staffPlannerDropDown", {
          startDate: this.dateDefault,
        })
        .then((res) => {
          this.staffList = res.data;
          this.indexColors = [];
          this.$refs.dateTd.forEach((element) => {
            this.indexColors.push(element.style.backgroundColor);
          });
          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
        });
    },

    getWindowHeight() {
      this.windowHeight = window.innerHeight;
    },

    handleClickEvent(event, job, x, y) {
      this.eventMenuData = [];

      if (this.editSelect) {
        if (this.$Can("event_edit_btn")) {
          this.eventMenuData.push(
            { icon: "UserPlusIcon", text: "Add Staff", job: job, x: x, y: y },
            { icon: "ClipboardIcon", text: "Add Note", job: job, x: x, y: y },
            { icon: "HomeIcon", text: "Add new Hotel", job: job, x: x, y: y }
          );
        }
      } else {
        if (this.$Can("event_information_tab")) {
          this.eventMenuData.push({
            icon: "FastForwardIcon",
            text: "Preview Event",
            job: job,
          });
        }

        if (this.$Can("event_edit_btn")) {
          this.eventMenuData.push(
            { icon: "EditIcon", text: "Edit Event", job: job },
            { icon: "UserPlusIcon", text: "Add Staff", job: job, x: x, y: y },
            { icon: "ClipboardIcon", text: "Add Note", job: job, x: x, y: y },
            { icon: "HomeIcon", text: "Add new Hotel", job: job, x: x, y: y },
            { icon: "CopyIcon", text: "Duplicate Event", job: job }
          );
        }
        if (this.$Can("event_delete_btn")) {
          this.eventMenuData.push({
            icon: "XIcon",
            text: "Remove Event",
            job: job,
          });
        }
      }

      if (job.length != 0) {
        this.$refs.eventMenuData.open(event, job);
      }
    },

    optionClickedEvent(text, data) {
      if (text == "Add new Hotel") {
        this.$bvModal.show("modal-hotel-0");

        this.newHotelJob = data.job;
        this.newHotelX = data.x;
        this.newHotelY = data.y;
      } else if (text == "Remove Event") {
        this.removeEventId = data.job;
        this.deleteEvent();
      } else if (text == "Duplicate Event") {
        this.duplicateData.id = data.job.id;
        this.disabledDates = [];
        data.job.group_dates.forEach((element) => {
          this.disabledDates.push(this.dateFormat(element.date));
        });

        this.$bvModal.show("modal-duplicate");
      } else if (text == "Preview Event") {
        router.push({ name: "event-view", params: { id: data.job.id } });
      } else if (text == "Edit Event") {
        router.push({ name: "event-edit", params: { id: data.job.id } });
      } else if (text == "Add Note") {
        this.noteEditing = data.job.id;
      } else if (text == "Add Staff") {
        // this.eventEmployeeListChange(data.job);
        this.$refs.staffComponentRef[0].eventEmployeeListChange(data.job);
      }
    },

    handleUpdateDuplicate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.modalUpdateDuplicate();
    },

    modalUpdateDuplicate(val) {
      if (!this.checkFormValidityDuplicate()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide("modal-duplicate");
      });

      this.formShow = true;

      var data = {
        id: this.duplicateData.id,
        date: this.duplicateData.date,
      };

      axiosIns
        .post("duplicateEvent", data)
        .then((response) => {
          this.duplicateData = {
            id: null,
            date: null,
          };

          this.getSelectWeek();
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    checkFormValidityDuplicate() {
      const duplicateValid = this.$refs.duplicateRules.checkValidity();

      return duplicateValid;
    },

    handleUpdateGroupDates(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.modalUpdateGroupDates();
    },
    modalUpdateGroupDates(val) {
      if (!this.checkFormValidityGroupDates()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide("modal-group-dates");
      });

      this.formShow = true;
    },
    checkFormValidityGroupDates() {
      const groupDatesValid = this.$refs.groupDatesRules.checkValidity();

      return groupDatesValid;
    },

    deleteEvent() {
      this.$swal({
        title: "Are you sure?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove the event!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (this.removeEventId.group_dates.length > 1) {
          this.$bvModal
            .msgBoxConfirm(
              "Would you like to delete the other days of this event?",
              {
                title: "Please Confirm",
                size: "sm",
                okVariant: "primary",
                okTitle: "Yes",
                cancelTitle: "No",
                cancelVariant: "outline-secondary",

                hideHeaderClose: false,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                centered: true,
              }
            )
            .then((value) => {
              if (value != null) {
                if (value) {
                  this.enabledGroupDates = [];

                  this.enabledGroupDates = this.removeEventId.group_dates
                    .map((e) => {
                      if (
                        this.dateFormatYmdToYmd(e.date) !=
                        this.removeEventId.date
                      ) {
                        return e.date;
                      }
                    })
                    .flat(1);

                  this.groupDates = this.removeEventId.group_dates
                    .map((e) => {
                      return this.dateFormat(e.date);
                    })
                    .flat(1)
                    .join(", ");

                  this.$bvModal.show("modal-group-dates");

                  const modalPromise = new Promise((resolve) => {
                    this.$refs["modal-group-dates"].$once("ok", () => {
                      resolve();
                    });
                  });

                  modalPromise.then(() => {
                    this.removeEventId.updateDates = this.groupDates
                      .split(",")
                      .map((e) => {
                        return this.dateFormatYMD(e);
                      });

                    axiosIns
                      .post(`deleteGroupEvents`, this.removeEventId)
                      .then((res) => {
                        this.getSelectWeek();
                        this.formShow = false;
                      })
                      .catch((error) => {
                        console.log("error delete ", error);
                      });
                  });

                  // this.removeEventId.updateDates = this.groupDates;

                  // axiosIns
                  //     .post(`deleteGroupEvents`, this.removeEventId)
                  //     .then((res) => {
                  //         this.getSelectWeek();
                  //         this.formShow = false;
                  //     })
                  //     .catch((error) => {
                  //         console.log("error delete ", error);
                  //     });
                } else {
                  this.formShow = true;

                  axiosIns
                    .delete(`deleteEvent/${this.removeEventId.id}`)
                    .then((res) => {
                      this.getSelectWeek();
                      this.formShow = false;
                    })
                    .catch((error) => {
                      console.log("error delete ", error);
                    });
                }
              }

              this.formShow = false;
            });
        } else {
          if (result.value) {
            this.formShow = true;

            axiosIns
              .delete(`deleteEvent/${this.removeEventId.id}`)
              .then((res) => {
                this.getSelectWeek();
                this.formShow = false;
              })
              .catch((error) => {
                console.log("error delete ", error);
              });
          }
        }
      });
    },

    groupDatesHandleCancel() {
      this.getSelectWeek();
    },

    availabilityCheck(id, date, eventId) {
      date = this.dateFormat(date);

      let availability = [];

      this.staffList.forEach((element) => {
        if (element.id == id) {
          element.availability_date.forEach((e) => {
            if (e.event_id != eventId || e.availability != "E") {
              const dStart = this.dateFormat(e.date);
              if (dStart == date) {
                availability.push({
                  availability: e.availability,
                  date: date,
                });
              }
            }
          });
        }
      });

      return availability;
    },

    deleteStart() {
      this.deleteSelect = !this.deleteSelect;
      this.deleteList = [];
    },

    deleteCheckboxAction(id, reference, value) {
      var tempIndexValue = null;
      if (value == true) {
        this.deleteList.push({
          id: id,
          reference: reference,
        });
      } else {
        tempIndexValue = this.deleteList.findIndex((item) => {
          return item.id == id;
        });
        this.deleteList.splice(tempIndexValue, 1);
      }
    },

    editStart() {
      this.editSelect = !this.editSelect;
      this.editList = [];
      this.editReference = null;
    },

    editCheckboxAction(id, reference, groupReference, value) {
      var tempIndexValue = null;

      if (this.editReference == null) {
        this.editReference = groupReference;
      }

      if (value == true) {
        this.editList.push({
          id: id,
          reference: reference,
        });
      } else {
        tempIndexValue = this.editList.findIndex((item) => {
          return item.id == id;
        });
        this.editList.splice(tempIndexValue, 1);
      }

      if (this.editList.length == 0) {
        this.editReference = null;
      }
    },

    deleteEvents() {
      var textValue = "";
      this.deleteList.forEach((element) => {
        textValue += "<p>" + element.reference + "</p>";
      });

      this.$swal({
        title: "Selected events will be removed. Are you sure?",
        // text: textValue,
        html: textValue,
        icon: "warning",
        width: "800px",
        showCancelButton: true,
        confirmButtonText: "Yes, remove the events!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          store
            .dispatch("home_planner/deleteEvents", this.deleteList)
            .then((res) => {
              this.deleteStart();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successful",
                  text: "✔️ Selected events have been deleted!",
                  icon: "ThumbsUpIcon",
                  variant: "success",
                },
              });
              this.getSelectWeek();
            })
            .catch((error) => {
              console.log(error);
              this.formShow = false;
            });
        }
      });
    },

    downloadPages() {
      let completedDownloads = 0;

      for (let i = 0; i < 3; i++) {
        this.printWeek(i)
          .then(() => {
            completedDownloads++;
            if (completedDownloads === 3) {
              this.formShow = false;
            }
          })
          .catch((error) => {
            console.log(error);
            this.formShow = false;
          });
      }
    },

    printWeek(pageNumber) {
      return new Promise((resolve, reject) => {
        store
          .dispatch("home_planner/printer", {
            startDate: this.dateDefault,
            clientId: this.userData.role === "Client" ? this.userData.id : null,
            page: pageNumber,
          })
          .then((res) => {
            if (res.status === 200) {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement("a");
              link.href = url;
              if (pageNumber == 0) {
                link.setAttribute("download", "Days_1-7.pdf");
              } else if (pageNumber == 1) {
                link.setAttribute("download", "Days_8-14.pdf");
              } else {
                link.setAttribute("download", "Days_15-21.pdf");
              }
              document.body.appendChild(link);
              link.click();

              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    staffAvailablityCheck(staffId, date) {
      var selectStaff = this.staffList.find((e) => {
        return e.id == staffId;
      });

      if (selectStaff) {
        var availabilitiesArray = selectStaff.availabilities;
        var checkAvailblity = availabilitiesArray.filter((e) => {
          return e.new_date == this.dateFormatDMY(date);
        });

        var test = checkAvailblity.some((e) => {
          return e.availability != "E";
        });

        if (test) {
          return 2;
        } else {
          if (checkAvailblity.length > 1) {
            return 1;
          } else {
            return 0;
          }
        }
      }

      return 0;
    },

    singleEventClick(event) {
      if (this.$Can("event_information_tab") && event.id != null) {
        router.push({ name: "event-view", params: { id: event.id } });
      }
    },

    onMailClose() {
      this.mailShow = false;
      this.selectMailList = [];
    },

    onMailSend() {
      this.mailShow = false;
      this.formShow = true;
      if (this.selectMailList.length > 0) {
        store
          .dispatch("home_planner/sendSelectWeek", {
            startDate: this.dateDefault,
            mailList: this.selectMailList,
          })
          .then((res) => {
            this.$swal({
              title: "E-mail sent",
              text: "Planner files sent to mailing list.",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "OK",
            });

            this.formShow = false;
          })
          .catch((error) => {
            console.log(error);
            this.formShow = false;
          });
      } else {
        this.formShow = false;
        this.$swal({
          title: "Mails List is Empty",
          text: "You have not added any mail to the mailing list.",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "OK",
        });
      }
    },
    onMailShow() {
      this.activeMailList = this.staffList
        .filter((x) => x.email != null)
        .map((x) => {
          var tempRow = {
            full_name: x.name + " " + (x.surname ?? ""),
            email: x.email,
          };
          return tempRow;
        });
    },

    mailSelect() {
      this.selectMailList.push(this.selectMail);
      this.selectMail = null;
    },

    mailTextSelect() {
      if (this.selectTextMail != "" && this.selectTextMail != null) {
        this.selectMailList.push(this.selectTextMail);
        this.selectMail = null;
        this.selectTextMail = null;
      }
    },

    removeMailList(index) {
      if (index !== -1) {
        this.selectMailList.splice(index, 1);
      }
      //this.selectMailList.splice();
    },
  },
};
</script>

<style lang="scss" scoped>
#plannerCover {
  font-size: 0.9em;
  line-height: 1em;
}

#buttonsCard {
  // position: sticky;
  top: 130px;
}

.weekNumbers {
  margin-right: 0.1em;
  margin-bottom: 0.1em;
  font-size: 0.85em;
  padding: 0.4em;
  height: 2em;
  width: 2em;
}

.allWeeks {
  margin-right: 0.1em;
  margin-bottom: 0.1em;
  font-size: 0.85em;
  padding: 0.4em;
  height: 2em;
}

#weeksTwentyOne {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

#weeksTwentyOne::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#weeksTwentyOne::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

#weeksTwentyOne::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #133842;
}

td {
  min-width: 130px;
  text-align: center;
  vertical-align: middle;
  height: 20px;
  border-bottom: 0px groove #00000020;
}

th {
  position: sticky;
  left: 0;
  min-width: 150px;

  background-color: rgb(0, 64, 75);
  color: white;
  white-space: nowrap;
  vertical-align: middle;
  border-bottom: 0px groove #00000020;
  padding-left: 5px;
  outline: 1px groove #00000020;
}

// :nth-child(-n + 5)
thead {
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;
  background-color: white;
  // border: 1px solid black;
}

tbody td {
  z-index: 9;
}

.leftTitle {
  z-index: 9;
  vertical-align: middle;
  border-bottom: 0px groove #00000020;
  padding-left: 5px;
  outline: 1px solid #00000020;
}

.plannerTd {
  z-index: 9;
  outline: 1px solid #00000020;
  padding-bottom: 5px;
  padding-top: 5px;
  vertical-align: middle;
  max-width: 150px;
}

.plannerTdStaff {
  z-index: 9;
  outline: 1px solid #00000020;
  padding-bottom: 0px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: top;
}

li {
  list-style-type: none;
}

.plannerTdStaff .wwsuccess {
  padding: 0.25rem 0.5rem;
  text-align: center;
  box-shadow: 0 0 5px #82868b;
  white-space: nowrap;
  font-size: 0.8em;
}

.errorStaff {
  outline: 1px solid rgba(255, 0, 0, 0.1);
  box-shadow: 0 0 8px 1px red !important;
  background-color: rgba(255, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid black;
}

.warningStaff {
  outline: 1px solid rgba(255, 0, 0, 0.1);
  box-shadow: 0 0 8px 1px blue !important;
  background-color: rgba(255, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid black;
}

.select-size-sm .vs__search {
  height: 2px;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(120px);
}

.noteTextArea {
  background-color: #fdfd96;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
  top: 0;
  width: 90%;
  max-width: 150px;
  word-wrap: break-word;
  // margin-left: 5px;
  // margin-right: 5px;
}

.noteTextArea::-webkit-scrollbar {
  width: 8px;
}

.noteTextArea::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #133842;
}

.wsuccess {
  background-color: #5cb85c;
  text-align: center;
  // padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  // padding-right: 0.15rem;
  // padding-left: 0.15rem;
  // box-shadow: 0 0 5px #82868b;
  font-size: 0.8rem;
  color: white;
}

.wwarning {
  background-color: #f0ad4e;
  text-align: center;
  // padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  // padding-right: 0.15rem;
  // padding-left: 0.15rem;
  // box-shadow: 0 0 5px #82868b;
  font-size: 0.8rem;
  color: white;
}

.wwsecondary {
  background-color: #6e6e6e;
  text-align: center;
  // padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  // padding-right: 0.15rem;
  // padding-left: 0.15rem;
  // box-shadow: 0 0 5px #82868b;
  font-size: 0.8rem;
  color: white;
}

.fakeBadge {
  display: inline-block;
  padding: 0.4em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  margin-right: 0.4em;
  margin-left: 0.4em;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #0d272e;
  color: white;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~vue-context/dist/css/vue-context.css";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.vue-treeselect__value-remove {
  display: none;
}

.b-overlay {
  inset: -30px !important;
}
</style>

<style>
.shortcut-buttons-flatpickr-button {
  width: 28%;
  border-radius: 5px;
  background: white;
  border: 1px solid #d8d6de;
  font-size: small;
}
</style>
